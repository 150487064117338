<template>
  <v-app>
    <v-container fluid>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-row class="align-center justify-center">
          <v-col cols="2" class="pa-1">
            <span> Tên đăng nhập</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="nick_name"
                rules="required"
                v-slot="{ errors }"
            >
              <hnr-input :text="user.nick_name" @input="update" v-model="user.nick_name"></hnr-input>

              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row v-if="isCreate" class="align-center justify-center">
          <v-col cols="2" class="pa-1">
            <span> Mật khẩu </span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="password"
                rules="required"
                v-slot="{ errors }"
            >
              <hnr-input-password :text="user.password" @input="update" v-model="user.password"></hnr-input-password>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>

          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col cols="2" class="pa-1">
            <span> Họ và tên</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="full_name"
                rules="required"
                v-slot="{ errors }"
            >
              <hnr-input :text="user.full_name" @input="update" v-model="user.full_name"></hnr-input>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>


        <v-row class="align-center justify-center">
          <v-col cols="2" class="pa-1">
            <span> Email</span>
          </v-col>
          <v-col cols="10" class="pa-1"><ValidationProvider
              name="email"
              rules="required"
              v-slot="{ errors }"
          >
            <hnr-input :text="user.email" @input="update" v-model="user.email"></hnr-input>
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          </v-col>
        </v-row>

        <v-radio-group v-model="user.authority">
          <v-row>
            <v-col cols="2" class="pa-1">
              <span> Phân quyền </span>
            </v-col>

            <v-col cols="2" class="pa-0 ma-0">
              <v-radio
                  label="Admin"
                  :value="2"
              ></v-radio>
            </v-col>
            <v-col cols="2" class="pa-0 ma-0">
              <v-radio
                  label="User"
                  :value="1"
              ></v-radio>
            </v-col>
          </v-row>
        </v-radio-group>

        <v-row class="align-center justify-center">
          <v-col cols="2" class="pa-1">
            <span> Active </span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <v-switch v-model="user.status"></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="!isCreate" class="align-center justify-center">
          <v-col cols="2" class="pa-3">
            <v-btn color="primary" @click="updateUser">Cập nhật người dùng</v-btn>
          </v-col>
          <v-col cols="2" class="pa-3">
            <v-btn color="primary" @click="showDialog">Thay đổi mật khẩu</v-btn>
          </v-col>
        </v-row>

        <v-row v-else class="align-center justify-center">
          <v-col cols="6" class="pa-0">
            <v-btn color="primary" @click="handleSubmit(createUser)">Tạo người dùng</v-btn>
          </v-col>
        </v-row>

        <v-dialog
            v-model="dialog"
            max-width="600px"
            min-height="600px"
        >
          <v-card>
            <v-card-title class="justify-center">
              <span class="headline">Nhập mật khẩu mới cho người dùng</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="align-center justify-center ma-0">
                  <hnr-input-password :text="user.password" @input="update"
                                      v-model="user.password"></hnr-input-password>
                </v-row>
                <v-row class="align-center justify-center ma-0 pa-4">
                  <v-btn color="primary" @click="changePassword">Thay đổi mật khẩu</v-btn>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </ValidationObserver>
    </v-container>
  </v-app>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrInputPassword from '@/components/elements/hnr-input-password'
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {DETAIL_USER, CREATE_USER, UPDATE_USER, CHANGE_PASSWORD} from "@/store/user.module";
import {SUCCESS, ERROR} from "@/store/alert.module";

export default {
  data() {
    return {
      radioGroup:2,
      userId: '',
      isCreate: true,
      user: {
        nick_name: "",
        full_name: "",
        email: "",
        password: "",
        role: 0,
        status: 0
      },
      admin: 1,
      dialog: false
    };
  },
  components: {
    HnrInput,
    HnrInputPassword
  },
  computed: {
    ...mapState({
      detailUser: state => state.user.user
    }),
  },
  watch: {
    detailUser: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.user = newVal;
        }
      }
    }
  },
  created() {
    this.userId = this.$route.params.userId;
    if(!this.userId){
      this.user.nick_name = " ";
      this.user.password = " ";
      this.user.authority = 1;
    }
  },
  mounted() {
    if (this.userId) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Sửa thông tin người dùng"}]);

      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_USER, {user_id: this.userId}).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm người dùng"}]);
    }
  },
  methods: {
    onClick() {
    },
    update() {
      this.$emit("input");
    },
    showDialog() {
      this.user.password = "";
      this.dialog = true;
    },
    updateUser() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(UPDATE_USER,
          {
            user_id: this.userId,
            nick_name: this.user.nick_name,
            full_name: this.user.full_name,
            email: this.user.email,
            authority: this.user.authority,
            status: this.user.status ? 1 : 0
          }).then(data => {
        this.$store.dispatch(SET_LOADING, false);
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Update người dùng thành công!", {root: true});
          this.$router.go(-1);
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {root: true});
          }else{
            this.$store.dispatch(ERROR, "Update người dùng thất bại!", {
              root: true,
            });
          }
        }
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
        this.$store.dispatch(ERROR, "Update người dùng thất bại!", {root: true});
      });
    },
    createUser() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(CREATE_USER,
          {
            user_id: this.userId,
            nick_name: this.user.nick_name,
            full_name: this.user.full_name,
            email: this.user.email,
            password: this.user.password,
            authority: this.user.authority,
            status: this.user.status ? 1 : 0
          }).then(data => {
        this.$store.dispatch(SET_LOADING, false);
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Tạo người dùng thành công!", {root: true});
          this.$router.go(-1);
        } else {
          if(data.errorCode == 425){
            this.$store.dispatch(ERROR, data.message, {root: true});
          }else{
            this.$store.dispatch(ERROR, "Tạo người dùng thất bại!", {
              root: true,
            });
          }
        }
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
        this.$store.dispatch(ERROR, "Tạo người dùng thất bại!", {root: true});
      });
    },
    changePassword() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(CHANGE_PASSWORD, {email: this.user.email, password: this.user.password}).then(data => {
        this.$store.dispatch(SET_LOADING, false);
        this.dialog = false;
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Thay đổi mật khẩu thành công!", {root: true});
          this.$router.go(-1);
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {root: true});
          }else{
            this.$store.dispatch(ERROR, "Thay đổi mật khẩu thất bại!", {
              root: true,
            });
          }
        }
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
        this.$store.dispatch(ERROR, "Thay đổi mật khẩu thất bại!", {root: true});
      });
    }
  },
};
</script>

<style lang="scss">
</style>
